import React from "react";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import styles from "./styles";
import { Container, Row, Col } from "react-bootstrap";
import { WebHeader, WebFooter } from "../../components";
const Csae = () => {
  return (
    <div>
      <WebHeader isChildPage={true} bannerTitle={"Terms of Use"} />
      <section>
        <div className={`px-5 ${css(AppStyles.container)}`}>
          <div className={css(styles.view)}>
            <h2 className={`mb-3 ${css([styles.heading])}`}>
            Qcast LTD Child Safety Standards Policy
            </h2>
            {/* <p className={`mb-3 ${css([styles.subHeading])}`}>TERMS OF USE</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              Unless otherwise specified, the terms of use detailed in this
              section apply generally when using this Website. Single or
              additional conditions of use or access may apply in specific
              scenarios and in such cases are additionally indicated within this
              document. By using this Website, Users confirm to meet the
              following requirements: There are no restrictions for Users in
              terms of being Consumers or Business Users; Users must be older
              than 18;
            </p>

            <p className={`mb-3 ${css([styles.subHeading])}`}>
              Account registration
            </p>

            <p className={`mb-3 ${css(styles.description)}`}>
              {`To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.
Failure to do so will cause unavailability of the Service.

Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Website.

By registering, Users agree to be fully responsible for all activities that occur under their username and password.
Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.`}
            </p> */}
            <p className={`mb-3 ${css([styles.subHeading])}`}>1.	Purpose</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              {`Qcast LTD is committed to creating a safe and secure online environment for children and vulnerable users. This policy establishes strict safety standards to prevent, detect, and address Child Sexual Abuse and Exploitation (CSAE) and Child Sexual Abuse Material (CSAM) across all Qcast platforms.`}
            </p>
            
            
            <p className={`mb-3 ${css([styles.subHeading])}`}>2.	Scope</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`This policy applies to all Qcast LTD digital products, including mobile apps, web platforms, and third-party integrations. All employees, contractors, partners, and developers are required to comply with these standards.`}</p>
            
           
            <p className={`mb-3 ${css([styles.subHeading])}`}>3.	Published Standards Against CSAE</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`By Qcast LTD strictly prohibits any form of CSAE, including but not limited to:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
              <li>The sharing, storage, or distribution of CSAM.</li>
              <li>The use of Qcast platforms for grooming, exploitation, or solicitation of minors.</li>
              <li>Any content, media, or interactions that promote or facilitate CSAE.</li>
              </ul>
            <p className={`mb-3 ${css(styles.description)}`}>{`Developers must ensure that all applications comply with these standards and integrate proactive safety measures.`}</p>
            
            <p className={`mb-3 ${css([styles.subHeading])}`}>4.	In-App Mechanism for User Feedback</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`All Qcast platforms must include a clear and accessible in-app reporting mechanism that allows users to:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
              <li>Report CSAE or CSAM violations.</li>
              <li>Provide feedback regarding safety concerns.</li>
              <li>Flag inappropriate content related to child safety.</li>
              </ul>
              <p className={`mb-3 ${css(styles.description)}`}>{`Reports must be reviewed and acted upon in accordance with Qcast LTD’s Child Safety Incident Response Plan.`}</p>


            <p className={`mb-3 ${css([styles.subHeading])}`}>5.	Addressing Child Sexual Abuse Material (CSAM)</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`To mitigate the risks of CSAM, Qcast LTD enforces:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
              <li>Automated and human moderation to detect and remove CSAM.</li>
              <li>AI-based content screening tools to prevent CSAM distribution.</li>
              <li>Strict enforcement against users and accounts involved in CSAM activities, including account suspension and legal reporting.</li>
              </ul>

            <p className={`mb-3 ${css([styles.subHeading])}`}>6.	Compliance with Child Safety Laws</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`TAll Qcast applications must adhere to local and international child safety laws, including:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
            <li>The Children’s Online Privacy Protection Act (COPPA) (U.S.).</li>
            <li>The Online Safety Act (U.K.).</li>
            <li>General Data Protection Regulation (GDPR) – Child Privacy Guidelines (EU).</li>
            <li>Tech Coalition’s best practices for Combating Online CSEA.</li>
           
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>{`Developers must ensure compliance with all applicable regulations and implement necessary safeguards.`}</p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>7.	Child Safety Point of Contact</p>
            
            <p className={`mb-3 ${css(styles.description)}`}>{`Qcast LTD designates a Child Safety Officer (CSO) as the primary point of contact for child safety concerns. The CSO is responsible for:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
            <li>Reviewing and escalating reported CSAE cases.</li>
            <li>Coordinating with law enforcement and child protection agencies.</li>
            <li>Providing guidance on child safety best practices within Qcast platforms.</li>
           
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>{`Contact Information:`}</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`Child Safety Officer: Tayo Dada, Email: `}<a href="mailto:childsafety@qcast.io">childsafety@qcast.io</a> Phone: <a href="tel:02031372459">02031372459</a></p>
           
            <p className={`mb-3 ${css([styles.subHeading])}`}>8.	Enforcement & Non-Compliance Consequences</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`Failure to comply with this policy may result in enforcement actions, including:`}</p>
            <ul className={`mb-3 ${css(styles.description)}`}>
            <li>Removal of non-compliant applications from Qcast LTD platforms.</li>
            <li>Suspension or termination of developer accounts.</li>
            <li>Legal actions in coordination with relevant authorities.</li>
           
            </ul>
           
            <p className={`mb-3 ${css([styles.subHeading])}`}>9.	Policy Review & Updates</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`This policy will be reviewed annually and updated as necessary to align with evolving child safety standards and legal requirements. All developers must stay informed of updates and maintain compliance.`}</p>
            
            
            <p className={`mb-3 ${css([styles.subHeading])}`}>10.	Additional Resources</p>
            <p className={`mb-3 ${css(styles.description)}`}>{`For more information on best practices, visit the Tech Coalition’s Best Practices for Combating Online CSEA and other child safety resources provided by industry organizations.
`}</p>
       
            
            <p className={`mb-3 ${css([styles.subHeading])}`}>By adhering to this policy, Qcast LTD aims to foster a safer online environment for all users, particularly children</p>





          </div>
        </div>
      </section>
      <WebFooter />
    </div>
  );
};

export default Csae;
