// @flow
import React, { useRef,useState } from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./ContactStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import EmailIconBlue from "../../assets/images/emailIconBlue/emailIconBlue.png";
import PhoneIconBlue from "../../assets/images/phoneIconBlue/phoneIconBlue.png";
import emailjs from '@emailjs/browser';
export default function ContactView(props) {
  const [email,setEmail]=useState('');
  const [message,setMessage]=useState('');
  const [name,setName]=useState('');
  const [error,setError]=useState('');
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_atmcxyj', 'template_612ncj7', form.current, 'JoBL9Ho4ygxSQ3nj5')
      .then((result) => {
        setEmail("")
        setName("")
        setMessage("")
        setError("Message Sent Successfully")
      }, (error) => {
          console.log(error.text);
      });
  };
  function emailChangeHandler(event){
    setEmail(event.target.value)
  }
  function nameChangeHandler(event){
    setName(event.target.value)
  }
  function messageChangeHandler(event){
    setMessage(event.target.value)
  }
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Got a question for us?</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={css(styles.description)}>
              Speak with our team today to discover how you can start putting questions to work.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col lg="6" md="12">
              <h1 className={css(styles.textInputHeading)}>
                Send us a message
              </h1>
              <p>{error}</p>

              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  className={css(styles.textInput)}
                  placeholder={"Your Name*"}
                  name="user_name"
                  required
                   value={name}
                   onChange={nameChangeHandler}
                />

                <input
                  type="email"
                  className={css(styles.textInput)}
                  placeholder={"Your Email*"}
                  name="user_email"
                  required
                   value={email}
                   onChange={emailChangeHandler}
                />

                <textarea
                  placeholder={"Your message*"}
                  className={css(styles.textArea)}
                  name="message"
                  required
                  value={message}
                  onChange={messageChangeHandler}
                ></textarea>

                <input
                  type="submit"
                  className={css(styles.submitButton)}
                  placeholder={"Password"}
                  value="Send"
                />
              </form>
            </Col>

            <Col lg="6" md="12">
              <h1 className={css(styles.textInputHeading)}>
                Contact Information
              </h1>

              <div className={css(styles.informationView)}>
                <img src={EmailIconBlue} />
                <a
                  href="mailto:contact@qcast.io"
                  className={css(styles.informationText)}
                  target="_blank"
                >
                  contact@qcast.io
                </a>
              </div>

              <div className={css(styles.informationView)}>
                <img src={PhoneIconBlue} />
                <a
                  href="tel:+44 208 088 9765"
                  className={css(styles.informationText)}
                >
                  +44 208 088 9765
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
