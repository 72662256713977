import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "../constants";
import {
  LandingPage,
  PageNotFound,
  TermsOfUse,
  PrivacyPolicy,
  About,
  Team,
  Portal,
  Contact,
  Document,
  UsesCases,
  Blog,
  Satelite,
  Faq,
  Download,
  Career,
  Press,
  Pricing,
  Sitemap,
  SustainabilityAndEnvironment,
  CorporateSocialResponsibility,
  UseOfAPI,
  SateliteVideo,
  CookiesPolicy,
  TermsOfService,
  SataliteMenu,
  Inline,
  Workspace,
  Csae
} from "../pages";
import LayoutWrapper from "./LayoutWrapper";

const NoAuthRoute = ({ ...props }) => {
  return (
    <LayoutWrapper>
      <Route {...props} />
    </LayoutWrapper>
  );
};

class Routers extends React.PureComponent {
  render() {
    const { user } = this.props;
    const repeatedProps = {
      user,
      exact: true,
      forAdminOnly: false,
    };
    return (
      <Router>
        <div>
          <Switch>
            <NoAuthRoute
              path={ROUTES.HOME}
              component={LandingPage}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.ABOUT}
              component={About}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.TEAM}
              component={Team}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.SITEMAP}
              component={Sitemap}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.CONTACT}
              component={Contact}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.CAREER}
              component={Career}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.WORKSPACE+"/:id"}
              component={Workspace}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.SUSTAINABILITY_AND_ENVIRONMENT}
              component={SustainabilityAndEnvironment}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.CORPORATE_SOCIAL_RESPONSIBILITY}
              component={CorporateSocialResponsibility}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.USE_OF_API}
              component={UseOfAPI}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.PRICING}
              component={Pricing}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.PRESS}
              component={Press}
              {...repeatedProps}
            />

            <NoAuthRoute path={ROUTES.FAQ} component={Faq} {...repeatedProps} />

            <NoAuthRoute
              path={ROUTES.USES_CASES}
              component={UsesCases}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.DOWNLOAD}
              component={Download}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.BLOG}
              component={Blog}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.SATELITE}
              component={Satelite}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.SATELITE+"/:id"}
              component={SataliteMenu}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.SATELITE+"/:menu/:id"}
              component={SateliteVideo}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.PORTAL}
              component={Portal}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.DOCUMENT}
              component={Document}
              {...repeatedProps}
            />

            <NoAuthRoute
              path={ROUTES.TERMS_AND_CONDITION}
              component={TermsOfUse}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.TERM_OF_USE}
              component={TermsOfUse}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.CSAE}
              component={Csae}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicy}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.INLINE_ALTON}
              component={Inline}
              {...repeatedProps}
            />
            <NoAuthRoute
              path={ROUTES.COOKIE_POLICY}
              component={CookiesPolicy}
              {...repeatedProps}
            />
            
  
          
            {/* Keep this in last always */}
            <NoAuthRoute path={Route.PAGE_NOT_FOUND} component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

const actions = {};

export default connect(mapStateToProps, actions)(Routers);
